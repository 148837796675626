<template>
    <div class="section-bgc intro"  >
        <div class="intro-item intro-item-type-1" :style="{backgroundImage: 'url(' + require('../assets/img/banner2.jpg') + ')'}">
            <div class="container" style="float: left;">
                <div class="row">
                    <div class="col">
                        <div  style="--margin-right: 4rem;">
                            <div  style="float: left;">
                                <p style="text-align: center;color:#0066FF;font-size:50px;">什么是渲染农场?
                                </p>
                                <p class="section-desc">&emsp;&emsp; 渲染农场又叫渲染集群或者集群渲染，是指通过使用分布式集群来解决单台计算机渲染性能不足的一种计算机软件技术，目前广泛应用于电影工业，动画，广告视频等领域，随着短视频兴起，人们越来越多的使用3D制作虚拟内容，因此也开始越来越多的直接或者间接的使用渲染农场技术.过去由于搭建渲染农场涉及的技术比较复杂，使用门槛比较高，现在，BlendStation通过集成各种技术并且极大的降低了搭建渲染农场的门槛，使得只需要有最简单计算机知识的艺术家或者工作室也可以搭建属于自己的渲染农场 </p>
                            
                            <div class="btn-group " style="float:center">
                                <a href="../#/download">
                                    <button>
                                           立刻开始 
                                    </button>
                                </a>
                            </div>
                            </div>
                        </div>
                     </div>
                 </div>
              </div>
           </div>
        </div>

</template>


<script>
export default {
  name: 'IntroShow',
  props: {
    Zmsg: String
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/css/bootstrap-grid.css";
@import "../assets/css/style.css";

h1 {
  font-size: 12px; 
  font-weight: normal;
  color: #000000;

}

br{
color: red;
}
</style>

