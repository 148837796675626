<template>
  <AboutUs />
  <Footer />
</template>

<script>
import Footer from '../components/Footer.vue'
import AboutUs from '../components/AboutUs.vue'
//import Example from '../components/Example.vue'
//import "vue3-video-play/dist/style.css";
//import { videoPlay } from "vue3-video-play";



export default {
  name: 'App',
  components: {
    Footer,
    AboutUs,
  //  Example,
  //  videoPlay,
   
  }
}
</script>

