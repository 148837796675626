<template>
  <div class="notfound">
    <h1>Page not found</h1>
  </div>
</template>

<style>
h1 {
  font-size: 12px; 
  font-weight: normal;
  color: black;
}
</style>
