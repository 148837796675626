function getOS() {
  var OS = "windows"; //Default

  if (navigator.appVersion.indexOf("Win") != -1) {
    if (navigator.userAgent.indexOf('Windows NT 5.0') == -1 &&
      navigator.userAgent.indexOf('Windows NT 5.1') == -1 &&
      (navigator.userAgent.indexOf('Win64') > -1 ||
        navigator.platform == 'Win64' ||
        navigator.userAgent.indexOf('x86_64') > -1 ||
        navigator.userAgent.indexOf('x86_64') > -1 ||
        navigator.userAgent.indexOf('amd64') > -1 ||
        navigator.userAgent.indexOf('AMD64') > -1 ||
        navigator.userAgent.indexOf('WOW64') > -1
      )) {
      OS = "windows-64";
    } else {
      if (window.external && window.external.getHostEnvironmentValue && window.external.getHostEnvironmentValue('os-architecture').indexOf("ARM64") !== -1) {
        OS = "windows-arm";
      } else {
        try {
          var canvas = document.createElement('canvas');
          var gl = canvas.getContext('webgl');

          var debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
          var renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
          if (renderer.indexOf("Qualcomm") !== -1)
            OS = "windows-arm";
        } catch (e) {}
      }
    }
  }

  //MacOS, MacOS X, macOS
  if (navigator.appVersion.indexOf("Mac") != -1) {
    if (navigator.platform.indexOf("MacPPC") != -1 || navigator.platform.indexOf("PowerPC") != -1) {
      OS = "macos-PPC";
    } else if (navigator.userAgent.indexOf("OS X 10.5") != -1 ||
      navigator.userAgent.indexOf("OS X 10.6") != -1) {
      OS = "macos-32";
    } else {
      OS = "macos";
      try {
        var glcontext = document.createElement("canvas").getContext("webgl");
        var debugrenderer = glcontext ? glcontext.getExtension('WEBGL_debug_renderer_info') : null;
        var renderername = debugrenderer && glcontext.getParameter(debugrenderer.UNMASKED_RENDERER_WEBGL) || "";
        if (renderername.match(/Apple M/) || renderername.match(/Apple GPU/)) {
          OS = "macos-apple-silicon";
        }
      } catch (e) {}
    }
  }
  if (navigator.platform.indexOf("Linux") != -1) {
    console.error(navigator.userAgent);
    if ((navigator.userAgent.indexOf("Ubuntu") != -1) ||
      (navigator.userAgent.indexOf("ubuntu") != -1)) OS = "linux-ubuntu";
    else if (navigator.userAgent.indexOf("Debian") != -1) OS = "linux-debian";
    else if (navigator.userAgent.indexOf("Android") != -1) OS = "linux-android";
    else if (navigator.userAgent.indexOf("Mandriva") != -1) OS = "linux-mandriva";
    else if (navigator.userAgent.indexOf("Red Hat") != -1) OS = "linux-redhat";
    else if (navigator.userAgent.indexOf("Fedora") != -1) OS = "linux-fedora";
    else if (navigator.userAgent.indexOf("SUSE") != -1) OS = "linux-suse";
    else if (navigator.userAgent.indexOf("Gentoo") != -1) OS = "linux-gentoo";
    else OS = "linux";
    console.error(OS);
  }

  if (navigator.userAgent.indexOf("iPad") != -1 || navigator.userAgent.indexOf("iPhone") != -1 || navigator.userAgent.indexOf("iPod") != -1) {
    OS = "ios";
  }
  if (navigator.platform.indexOf("freebsd") != -1) OS = "freebsd";
  if (navigator.platform.indexOf("FreeBSD") != -1) OS = "freebsd";

  return OS;
}


        window.onload = function() {
            console.error("window.onload = " + 111)
        }
        /* Populate a warning alert for specific platforms. */
	function showPlatformWarning(content){
		console.error("showPlatform");
		$('.dl-build-details, .dl-build-details-popup').addClass('has-alert alert-warning');
		$('.popup-toggle').html('<i class="i-alert-triangle"></i>');

		$('.platform-warning')
			.addClass('show')
			.html(content);
	}

	$(document).ready(function(	){
		console.error("document.ready")
		$('.js-toggle-menu').on('click', function(e){
			        console.error("popups start");
				e.stopPropagation();

				let toggle = $(this).data('toggle-menu');
				let menu = document.getElementById(toggle);
                                
			        console.error(toggle);
			        console.error(menu);
				$(this).toggleClass('active');
				$(menu).toggleClass('active');
			        console.error(menu);
			        console.error(this);
		});

		/* Hide all Download buttons. */
		$('[class*="dl-os-"]').hide().removeClass('active');

		/* Display only one group of Download buttons based on OS, fallback to Windows. */
		let downloadButtons = document.getElementsByClassName('dl-os-windows');

		/* Get the current operating system. See get_os.js */
		let os = getOS();

		/* Windows. */
		if (os == 'windows') {
			showPlatformWarning('This Windows version might not be supported.');
		}
		else if (os == 'windows-arm') {
			showPlatformWarning('Blender is not available for Windows ARM architecture yet.');
		}
		/* Linux. */
		else if (os.startsWith('linux') || os.startsWith('freebsd')) {

			/* Set the Download button platform to Linux. */
			downloadButtons = document.getElementsByClassName('dl-os-linux');

			if (os == 'freebsd') {
				showPlatformWarning('There are no official builds for FreeBSD yet.');
			}
		}
		/* macOS. */
		else if (os.startsWith('macos') || os.startsWith('ios')) {

			/* Set the Download button platform to macOS. */
			downloadButtons = document.getElementsByClassName('dl-os-macos');
			
			if (os == 'macos-apple-silicon') {
				downloadButtons = document.getElementsByClassName('dl-os-macos-apple-silicon');

				/* Safari does not have a reliable way to detect if the system is Intel or Apple Silicon,
				 * Show Download buttons for both systems for the time being until navigator.gpu is supported. */
				let is_safari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;

				if (is_safari) {
					downloadButtons = document.querySelectorAll('[class^="dl-header-cta dl-os-macos"]');
				}
			} else if (os == 'macos-32' || os == 'macos-PPC') {
				showPlatformWarning('This macOS version might not be supported.');
			}
			else if (os == 'ios') {
				showPlatformWarning('Blender is not available for iOS yet.');
			}
		}

		/* Show the Download button for the current OS. */
		for (var i = 0; i < downloadButtons.length; i++) {
			console.error("download button");
			downloadButtons[i].style.display = 'block';
			downloadButtons[i].classList.add('active');
		}

		/* Style the other platforms button, so we can highlight alternative builds on the same platform. */
		$('#menu-other-platforms').addClass('dl-other-list-os-' + os);
		$('.dl-header-other').addClass('current-os-' + os);

		/* Click anywhere on the page to hide these popups. */
		$(document).click(function () {
			console.error("hide popups");
			$('.dl-togglable, .js-toggle-menu').removeClass('active');
		});

		/* Register stats for download. */
		$('.js-download').click(function(e){
			console.error("register download");
			dl_stats = $(this).attr('dl_stats');
			/*ga('send', 'event', 'button', 'download', dl_stats);*/
		});
	});
