<template>
  <FrequentQuestion />
  <Footer />
</template>

<script>
import FrequentQuestion from '../components/FrequentQuestion.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'App',
  components: {
    FrequentQuestion,
    Footer
  }
}
</script>

