<template>


<div class="video-background" width=100%>
    <!--<video  class="fullscreenvideo"  poster="__ROOT__/Themes/tdt/Asset/images/loginbg.jpg" id="bgvid" playsinline="" autoplay="" muted="" loop=""  >
        <source src="@/assets/video/demo.mp4" type="video/mp4" />
    </video>-->

        <video  width=100%  autoplay="" loop="" muted="" poster="@/assets/video/preview1.jpg" src="@/assets/video/demo1.mp4">
haha</video>



            <div class="content">
	        <div class="row">
		    <div class="col">
		 	<div class="intro-content" style="--margin-left: 4rem;">
                            <br><br><br><br><br><br><br><br>
		       	    <div class="section-heading shm-none ">
				<div class="section-subheading">MeshTek</div>
			      	<div style="font-size:32px">BlendStation
				   <br>免费轻量级渲染农场软件
<!--
                                   <br>功能强大，支持渲染与合成，支持多通道文件输出
                                   <br>性能超强，一般的blender后台命令行渲染速度还要快
-->
                                </div>
				<p class="section-desc" style="font-size:24px;color:#DEDEDE;">搅拌机工作站（BlendStation）——让你轻松搭建自己的blender本地渲染农场。</p>
                                <!--<p class="section-desc" style="font-size:16px;color:#DEDEDE;">内置blender 4.2 LTS核心模块，让支持blender最新特性的同时渲染速度更快</p>-->
			    </div>
			    <div class="section-subheading">
				<a href="../#/download">
                                    <button style="font-size:20px">
                                            前往下载
                                    </button>
                                </a>
                                &nbsp;&nbsp;
                                <a href="../#/docs/introduce">
                                    <button style="font-size:20px">
                                            文档
                                    </button>
                                </a>
 			    </div>
			</div>
		     </div>
		 </div>
	      </div>
	   </div>

</template>


<script>
export default {
  name: 'IntroShow',
  props: {
    Zmsg: String
  }
}
</script>


<style lang="scss" scoped>

@import "../assets/css/bootstrap-grid.css";
@import "../assets/css/style.css";

h1 {
  font-size: 12px; 
  font-weight: normal;
  color: #000000;

}

br{
color: red;
}



.video-background {
  position: relative; 
  width: 100%;
  height: 100vh; 
}
 
.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  position: absolute; 
  top: 0;
  left: 0;
}
 
.video-background .content {
  position: relative;
  top: 820; 
  left: 100;
  color: white; 
}
</style>

