<template>
    <div class="section-bgc intro"  >
        <div class="intro-item intro-item-type-1" :style="{backgroundImage: 'url(' + require('../assets/img/banner1.jpg') + ')'}">
            <div class="container" style="float: right;">
                <div class="row">
                    <div class="col">
			    <!--<div style="--margin-right: 4rm;">-->
	                 <div >
                            <div style="float:left;"><img sytle="top:50%;" src="@/assets/img/BlenderAndBlendStation.png" width=600> </div>
                        <!--<div  style="--margin-right: 4rem;">-->
                            <div  style="float:right;">
                                <div class="section-subheading">MeshTek</div>
                                <p style="text-align: center;color:#0066FF;font-size:40px;">Blender动画创作基础设施软件
                                </p>
                                <p class="section-desc">支持blender4.2 LTS版本，让您能够使用Blender当前主流特性</p>
                                <p class="section-desc">内置blender核心模块，让单节点性能更高，比Blender界面渲染和命令行渲染更快</p>
                                <p class="section-desc">支持常规渲染与合成渲染，支持多通道文件输出，让你能够进行专业级渲染</p>
                                <p class="section-desc">支持批量渲染，满足无人值守，满足业务繁忙场景需求</p>
                                <p class="section-desc">支持多种格式渲染，包括FFMPEG格式，无需后期合成处理,一步到位输出视频格式</p>
                                <p class="section-desc">Blender+BlendStation提升您的创作效率，让你更快出片</p>
                            
                                <div class="btn-group ">
                                    <a href="../#/download">
                                        <button>
                                           立刻下载 
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                     </div>
                 </div>
              </div>
           </div>
        </div>

</template>


<script>
export default {
  name: 'IntroShow',
  props: {
    Zmsg: String
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/css/bootstrap-grid.css";
@import "../assets/css/style.css";

h1 {
  font-size: 12px; 
  font-weight: normal;
  color: #000000;

}

br{
color: red;
}
</style>

