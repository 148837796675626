{<template>

  <div class="container">

    <div class="dropdown">

      <!-- 标题 -->

      <span class="dropdown-title"> 下拉菜单 </span>

      <!-- 下拉菜单内容 -->

      <div class="dropdown-content">

        <div class="dropdown-menu">

          <div class="menuItem">菜单1</div>

          <div class="menuItem">菜单菜单1</div>

          <div class="menuItem">菜单2</div>

          <div class="menuItem">菜单菜单菜单1</div>

          <div class="menuItem">菜单3</div>

        </div>

      </div>

    </div>

  </div>

</template>



<script>

export default {

  data() {

    return {};

  },

  mounted() {},

  methods: {},

};

</script>



<style lang="scss" scoped>

.container {

padding: 20px;

}



.dropdown {
margin: 0 20px;

.dropdown-title {
display: inline-block;
position: relative;
height: 60px;
line-height: 60px;
font-size: 20px;
color: #fff;
background-color: #000;
padding: 0 24px;
border-radius: 4px;
cursor: pointer;
}



.dropdown-content {
position: absolute;
visibility: hidden;
opacity: 0; 
transition: all 0.6s ease-in-out;

.dropdown-menu {
margin-top: 4px; 
padding: 10px 8px 15px;
color: white;
background-color: rgba($color: #000, $alpha: 0.8);
border-radius: 4px;

.menuItem {
width: 100%;
white-space: nowrap;
padding: 10px 16px;
font-size: 16px;
color: white;
cursor: pointer;
border-radius: 4px;

:hover {
background-color: #ccc;
}
}
}
}


:hover .dropdown-content {
visibility: visible;
opacity: 1;
}

}

</style>
