<template>
  <IntroShow />
  <Banner />
  <BannerTwo />
  <Advantage />
  <HardWare />
  <Os />
  <Footer />
</template>

<script>
import IntroShow from '../components/IntroShow.vue'
import Banner from '../components/Banner.vue'
import BannerTwo from '../components/BannerTwo.vue'
import HardWare from '../components/HardWare.vue'
import Os from '../components/Os.vue'
import Advantage from '../components/Advantage.vue'
import ExampleSlide from '../components/ExampleSlide.vue'
import Card from '../components/Card.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Footer from '../components/Footer.vue'
export default {
  name: 'App',
  components: {
    IntroShow,
    Banner,
    BannerTwo,
    HardWare,
    Os,
    Advantage,
    ExampleSlide,
    HelloWorld,
    Footer
  }
}
</script>

