<template>
                <p><strong>服务自定义配置</strong></p>
                <p>目前BlendStation主要涉及3个端口，BlendStation-Manager涉及2个端口分别是masterPort 22200 文件服务filePort 8080,BlendStation-Worker涉及一个端口workerPort 22201<br>
                由于在某些用户环境下可能与其他业务端口冲突导致服务无法启动，所以BlendStation提供了配置文件供修改,用户需要确保BlendStation-Manager和BlendStation-Worker中关于三个端口的配置一致性，否则就会导致网络通信问题
                </p>
                <ol>
                    <li>BlendStation-Manager配置</li>
                        配置文件位于与bin同一级的data目录下的manager.conf即data/manager.conf<br>
		        配置内容如下<br>
                    <code>
{<br>
    "masterAddress": "127.0.0.1",<br>
    "masterPort": 22200,<br>
    "filePort": 8080,<br>
    "workerPort": 22201<br>
}
                     </code>


                    <li>BlendStation-Worker配置</li>
                        配置文件位于与bin同一级的data目录下的workerer.conf即data/worker.conf<br>
                        配置内容如下<br>
                        <code>
{<br>
    "masterAddress": "127.0.0.1",<br>
    "masterPort": 22200,<br>
    "workerPort": 22201,<br>
    "filePort": 8080,<br>
    "workerConfigPath": "../data/worker.conf"<br>
}                     

                        </code>
                       <p>修改后重新启动对应的应用，也就是分别启动BlendStation-Manager和BlendStation-Worker</p>
                       <p>需要注意的是保持Manager和所有的Worker对应的配置一致性 </p>
                </ol>
</template>
