<template>
                <p><strong>批量管理</strong></p>
                <p>当我们在作业管理中添加了作业以后，我们可以在作业管理中直接开启渲染，细心的用户会发现在作业管理中只会渲染一个作业，渲染完成以后就会停止，不会去渲染其他作业，这个是因为作业管理是单作业模式，如果我们有一批作业需要渲染，又不想一个个等待渲染完成再去点击渲染下一个文件怎么办？BlendStation提供了批量管理这种针对业务量比较繁忙的用户场景，如何进行批量管理，需要按照以下步骤:</p>
                <ol>
                    <li>作业管理，在BlendStation管理中心软件 <em>blendstation-manager</em>进入作业管理界面，选择添加作业，然后在路径中选中要渲染的blend工程文件，然后添加. ,具体如何添加作业请参看<a href="/#/docs/job_manage">作业管理</a></li>
                    2. 添加作业成功后，我们进入批量管理页面，看到批量管理什么都没有，需要我们选择哪些需要进入批量渲染的作业

                    <img decoding="async" src="@/assets/img/usage/batch_init.png">
                    3. 添加作业，我们需要点击添加处理项,需要注意的是已经渲染完成的和出错的作业不会出现在可供添加的选择作业列表中
                   
                    <img decoding="async" src="@/assets/img/usage/batch_add.png">
                    4. 确认无误后点击添加, 在批量管理界面就可以看到刚才添加的作业 
                    <img decoding="async" src="@/assets/img/usage/batch_list.png">
                    5. 添加完所有要批量处理的作业以后，我们就可以点击批量处理的开始按钮
                    <img decoding = "async" src="@/assets/img/usage/batch_start.png">
                    6. 渲染完成时，你会看到所有的作业进度都是100%，批量处理按钮已经变成停止
                    <img decoding = "async" src="@/assets/img/usage/batch_finish.png">
                    7. 如果你在批量处理期间还要添加新的作业，请暂停批量处理后再添加处理项，只要批处理没有完成就可以添加，一旦批量处理完成，说明本次批量处理已经结束，要渲染作业就得清理当前列表后开始新的批量处理
                </ol>
</template>
