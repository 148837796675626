<template>


                <div class="aside" data-v-a3c25e27="">
                    <div class="aside-curtain" data-v-a3c25e27="">
                    </div>
                    <div class="aside-container" data-v-a3c25e27="">
                        <div class="aside-content" data-v-a3c25e27="">
                            <div class="VPDocAside" data-v-a3c25e27="" data-v-cb998dce="">
                                <div class="VPDocAsideOutline has-outline" role="navigation" data-v-cb998dce=""
                                data-v-3a6c4994="">
                                    <div class="content" data-v-3a6c4994="">
                                        <div class="outline-marker" data-v-3a6c4994="" style="top: 33px; opacity: 0;">
                                        </div>
                                        <div><br><br><br><br></div>
                                        <div class="outline-title" role="heading" aria-level="2" data-v-3a6c4994="">
                                           相关文章 
                                        </div>
                                        <nav aria-labelledby="doc-outline-aria-label" data-v-3a6c4994="">
                                            <span class="visually-hidden" id="doc-outline-aria-label" data-v-3a6c4994="">
                                                Table of Contents for current page
                                            </span>
                                            <ul  >
                                                <li >
                                                    <a  style = "font-size:12px;" href="/#/farm"
                                                    >
                                                       垃圾佬如何用二手淘汰机器搭建Blender渲染集群 
                                                    </a>
                                                </li>
                                                <li data-v-463da30f="">
                                                    <a  class="outline-link" style="font-size:12px;" href="/#/bake"
                                                    title="Pushing Blender Development">
                                                        如何用BakeCacheTool导出烘焙缓存
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div class="spacer" data-v-cb998dce="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

</template>

<style lang="scss" scoped>
        @import "../assets/css/doc.scss";
</style>

