import Vue from 'vue';


import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';


import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css


//import popper from 'popper'
//Vue.prototype.$layer = layer(Vue);
import { createApp } from 'vue'

//createApp.prototype.$layer = layer(createApp);

import SwiperClass, { /* swiper modules... */ } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import swiper module styles
import 'swiper/css'
// more module style...

// use swiper modules
SwiperClass.use([/* swiper modules... */])


import App from './App.vue'

import {Collapse} from 'vue-collapsed'
var app =createApp(App)
//var app = createApp({
//  render: () => h(App),
//  delimiters: ['${', '}'],
//  whitespace: 'preserve'
//})
//app.config.compilerOptions.whitespace = 'preserve';
//app.config.compilerOptions.delimiters = ['${', '}']
//app.mixin()
//
app.use(vue3videoPlay).component('Collapse',Collapse).mount('#app')

//app.mount('#app')






