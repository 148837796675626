<template>
                <p><strong>快速开始</strong></p>
                <p>运行BlendStation的必要条件</p>
                <ul>
                    <li>一台或者多台用来工作的电脑,
                        也就是用来 运行 <strong>BlendStation 客户端软件</strong>. 你&rsquo;
                        无需单独安装 <a class="gdoc-markdown__link" href="https://www.blender.org/">Blender</a>.</li>
                    <li>一台用来运行控制中心软件的电脑,
                        <strong>BlendStaion 管理软件</strong>. 这个可以是上面电脑中的一台，或者是专门的一台电脑 .
                    </li>
                    <li>确保上面的机器在同一个局域网内，确保他们之间的网络能够相互联通.</li>
                </ul>
                <p>一般来说,
                    为了使用BlendStation进行集群渲染,
                    需要按照以下步骤:</p>
                <ol>
                    <li><a class="gdoc-markdown__link" href="https://www.meshtek.cn/#/download">下载 BlendStation</a>. 解压缩后，在bin目录下可以看到两个文件，一个叫BlendStation-manager，用来管理作业和集群的图形管理软件，另一个叫BlendStation-worker，主要完成具体渲染任务 </li>
                    <li>在每一台用来渲染的电脑上安装BlendStation客户端，也就是运行<code>blendstation-worker</code>. 可以在任意路径下运行，不必要求相同.</li>
                    <li>选择一台电脑作为管理整个集群的电脑上安装BlendStation管理中心,
                        在上面运行 <code>blendstation-manager</code>. </li><img decoding="async" src="@/assets/img/help/start.png">
                    <li>添加机器，在BlendStaion管理中心软件<code>blendstation-manager</code>打开机器管理界面，选择添加机器，输入（第2步）安装BlendStation客户端软件的机器ip地址，点击连接机器，如果返回机器相关信息，说明机器联通，然后直接点击确定即可成功，如果有多台安装了BlendStation客户端的机器，则多次添加即可. </li>4.1 进入机器管理页面 <img decoding="async" src="@/assets/img/usage/no_machine.png">4.2 点击添加机器，然后输入IP地址 <img decoding="async" src="@/assets/img/usage/add_machine1.png">4.3 点击测试连接，如果IP地址对应的机器已经启动了BlendStaion-worker，那么就会返回机器相关信息 <img decoding="async" src="@/assets/img/usage/add_machine2.png">4.4 点击添加，然后机器列表里就可以看到刚才添加的机器 <img decoding="async" src="@/assets/img/usage/add_machine3.png">
                    <li>作业管理，在BlendStation管理中心软件 <em>blendstation-manager</em>进入作业管理界面，选择添加作业，然后在路径中选中要渲染的blend工程文件，然后添加. </li>5.1 进入作业管理界面 <img decoding="async" src="@/assets/img/usage/add_job0.png">5.2 点击"添加作业"

                    <img decoding="async" src="@/assets/img/usage/add_job1.png">5.3 选择blend工程文件并打开后，可以在窗口看到该作业相关信息 <img decoding="async" src="@/assets/img/usage/add_job2.png">5.4 确认无误后点击添加,
                    在作业管理界面就可以看到刚才添加的blend工程文件 <img decoding="async" src="@/assets/img/usage/add_job3.png">
                    <li>渲染blend文件，在BlendStation管理中心<em>blendstation-manager</em>进入作业管理界面，选择上一步添加的作业点击开始，就开始了你的集群渲染工作.</li>6.1 点击作业后面的开始绿色按钮,
                    然后按钮会变成黄色，代表已经开始渲染了 <img decoding="async" src="@/assets/img/usage/render_job1.png">
                    <li>查看渲染进度和机器工作情况，在BlendStaion管理中心<em>blendstation-manager</em>进入监控界面，可以查看到当前正在渲染的作业进度和各个机器的工作负载情况</li>7.1 开始渲染时 <img decoding="async" src="@/assets/img/usage/render_job2.png">7.2 渲染到中间时 <img decoding="async" src="@/assets/img/usage/render_job3.png">
                    <li>渲染完成后，在运行BlendStation管理中心运行目录上一级打开data下的Center目录，根据作业Id编号可以找到对应的渲染结果.</li><img decoding="async" src="@/assets/img/usage/render_out.png">
                </ol>
</template>
