<template>
<swiper
  :modules="modules"
  :allow-touch-move="true"
  :slides-per-view="2"
  :pagination="{ clickable: true }"  
  :autoplay="{ delay: 3500, disableOnInteraction: false }"
  @swiper="onSwiper"
  @slide-change="onSlideChange"
  @transition-start="onTransitionStart"
>
  <swiper-slide> <img src="@/assets/img/example/ex1.jpg" alt="支持Window,Linux" width="600" height="300" />  </swiper-slide>
  <swiper-slide> <img src="@/assets/img/example/ex2.jpg" alt = "支持x86,arm" width="600" height="300" /> </swiper-slide>
  <swiper-slide> <img src="@/assets/img/example/ex3.jpg" alt = "支持50-500台集群渲染" width="600" height="300" /> </swiper-slide>
  <swiper-slide> <img src="@/assets/img/example/ex4.jpg" alt = "支持50-500台集群渲染" width="600" height="300" /> </swiper-slide>

</swiper>
</template>


<script>
  import {Autoplay, Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

  // import swiper module styles
  import 'swiper/css'
  import 'swiper/css/pagination'
  // more module style...

  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
      return {
        modules: [Pagination,Autoplay]
      }
    }
  }
</script>
