<template>
                <p><strong>机器管理</strong></p>
                <p>一般来说,为了使用BlendStation进行集群渲染,首先要配置好渲染集群，如何配置渲染集群
                    需要按照以下步骤:</p>
                <ol>
                    <li>添加机器，在BlendStaion管理中心软件<code>blendstation-manager</code>打开机器管理界面，选择添加机器，输入（第2步）安装BlendStation客户端软件的机器ip地址，点击连接机器，如果返回机器相关信息，说明机器联通，然后直接点击确定即可成功，如果有多台安装了BlendStation客户端的机器，则多次添加即可. </li>
                      <p style="font-size:10px">未添加机器的机器管理页面 </p>
                     <img decoding="async" src="@/assets/img/usage/no_machine.png">
                     2. 点击添加机器，然后输入IP地址 
                     <img decoding="async" src="@/assets/img/usage/add_machine1.png">
                     3. 点击测试连接，如果IP地址对应的机器已经启动了BlendStaion-worker，那么就会返回机器相关信息 
                     <img decoding="async" src="@/assets/img/usage/add_machine2.png"> 
                     4. 点击添加，然后机器列表里就可以看到刚才添加的机器 
                     <img decoding="async" src="@/assets/img/usage/add_machine3.png">
                </ol>
</template>
