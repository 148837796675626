<template>
    <section class="page-card features">

        <div class="container" >
            <p class="page-card-title" style="text-align: center;font-size:50px;color:#0066ff;">为什么选择BlendStation？</p>

            <ul class="page-card-list">

                <li class="feature-item visible">
                    <div class="feature-details">
                        <div class="feature-title" style="font-size:30px;font-weight:bold;">开箱即用</div>
                        <div class="feature-desc">
                            <p style="font-size:20px;">菜鸟亦可轻松安装，无需进行复杂的网络、存储目录等配置</p>
                            <ul style="font-size:16px;">
                                <li>· <strong>一键安装:</strong>  无需专业计算网络知识，您只需要知道你的计算机IP地址即可</li>
                                <li>· <strong>配置简单:</strong>  只需一步配置即可完成集群机器配置</li>
                                <li>. <strong>无缝集成:</strong> 通过集成Blender，不再需要单独设置Blender，避免了版本不一致等错误</li>
                            </ul>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div data-url="@/assets/img/easy.jpeg" data-credits="Image by Midge Sinnaeve - mantissa.xyz" class="feature-image js-isolify">
                        <img decoding="async" src="@/assets/img/pd/good1.png">
                    </div>
                </li>


                <li class="feature-item visible">
                    <div class="feature-details">
                        <div class="feature-title" style="font-size:30px;font-weight:bold;">性能卓越</div>
                        <div class="feature-desc">
                            <p style="font-size:20px;"> <strong>集群和单节点均性能卓越 </strong></p>
                            <ul style="font-size:16px">
                                <li>· <strong>集群极致性能:</strong> 通过对各种影响集群性能的因素进行了综合优化，提升了整体集群的渲染性能</li>
                                <li>. <strong>单节点性能卓越:</strong>通过集成blender核心模块，提升了计算性能，使得单节点超过了传统的后台命令行渲染速度</li>
                            </ul>
                        </div>
                    </div>
                    <div data-url="@/assets/img/cheap.jpeg" data-credits="" class="feature-image js-isolify">
                        <img decoding="async" src="@/assets/img/pd/good2.png">
                    </div>
                </li>


                <li class="feature-item visible">
                    <div class="feature-details">
                        <div class="feature-title" style="font-size:30px;font-weight:bold;">节约成本</div>
                        <div class="feature-desc">
                            <p style="font-size:20px;"><strong>支持新老硬件，对于硬件要求更低</strong></p>
                            <ul style="font-size:16px">
                                <li>· <strong>集群软件免费: </strong>. BlendStation对于企业用户， 中小工作室和个人创作者以及学生均免费，不限制集群规模</li>
                                <li>· <strong>支持硬件类型丰富: </strong> 支持多种新老硬件，无论最新的4090显卡还是2015年x86 pc机器均有效支持</li>
                                <li>. <strong>硬件要求低: </strong>得益于内置Blender核心模块技术，相对于其他渲染农场技术路线要求使用更少的内存</li>
                            </ul>
                        </div>
                    </div>
                    <div data-url="@/assets/img/plat.jpeg" data-credits="Image by Pavel Siska" class="feature-image js-isolify">
                        <img decoding="async" src="@/assets/img/pd/good3.png">
                    </div>
                </li>


                <li class="feature-item">
                    <div class="feature-details">
			<!--<h1>跨平台支持</h1>-->
                        <div class="feature-title" style="font-size:30px;font-weight:bold;">跨平台支持</div>
                        <div class="feature-desc">
                            <p style="font-size:20px;"><strong>支持多种pc硬件，支持多种os</strong></p>
                            <ul style="font-size:16px">
                                <li>· <strong>支持多OS:</strong> 支持Linux,Windows</li>
                                <li>· <strong>支持混合架构:</strong>支持CPU+GPU,支持主流的X86平台，支持主流N卡和A卡GPU计算</li>
                                <li>. <strong>支持多引擎:</strong>支持常见的Cycles和EEVEE两种渲染引擎</li>
   				<li>. <strong>支持多种Cycles渲染设备: </strong>BlendStation会自动根据硬件配置类型选择CUDA模式Optix模式或者HIP模式进行计算</li>
                            </ul>
                        </div>
                    </div>
                    <div data-url="@/assets/img/artist.jpeg" data-credits="Spring character from Blender Cloud - cloud.blender.org/spring" class="feature-image js-isolify">
                        <img decoding="async" src="@/assets/img/pd/good4.png">
                    </div>
                </li>



                <li class="feature-item">
                    <div class="feature-details">
                        <!--<h1>跨平台支持</h1>-->
                        <div class="feature-title" style="font-size:30px;font-weight:bold;">功能强大丰富</div>
                        <div class="feature-desc">
                            <p style="font-size:20px;"><strong>功能强大，满足专业需求</strong></p>
                            <ul style="font-size:16px">
                                <li>· <strong>支持常见渲染:</strong>支持各种骨骼动画渲染，关键帧动画渲染，物理刚体渲染以及软体动画渲染等常见功能 </li>
                                <li>· <strong>支持合成渲染:</strong>支持合成器渲染，支持多通道文件输出，让你在自己的渲染农场也可以进行合成节点操作这类专业渲染操作</li>
                                <li>. <strong>支持批量渲染:</strong>让你一次批量多个作业渲染，对于业务繁忙的场景，满足无人值守需要</li>
                                <li>. <strong>支持视频格式:</strong>支持FFMPEG各种格式，无需后续其他软件合成操作，直接生成视频格式</li>
                            </ul>
                        </div>
                    </div>
                    <div data-url="@/assets/img/artist.jpeg" data-credits="Spring character from Blender Cloud - cloud.blender.org/spring" class="feature-image js-isolify">
                        <img decoding="async" src="@/assets/img/pd/good5.jpg">
                    </div>
                </li>


            </ul>
        </div>
    </section>


</template>

<script>
export default {
  name: 'Advantage'
}
</script>

