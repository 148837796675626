<template>
<footer class="footer footer-minimal">
    <div class="footer-main">
        <div class="container">
            <div class="row items align-items-center">
                <div class="col-lg-3 col-md-4 col-12 item">
                    <!--<div class="widget-brand-info">
                        <div class="widget-brand-info-main">
                            <a href="/#" class="logo" title="BlendStation">
                                <img  data-src="@/assets/img/cooltek.png" class="lazy" width="133" height="36" src="@/assets/img/cooltek.png" alt="PathSoft" data-loaded="true" style="opacity: 1;display:flex; justify-content: center;  "></a></div>
                    </div>-->
                    <div class="footer-widget footer-widget__about">
                        <img src="@/assets/img/cooltek.png" height="60">
                        <strong class="nav-global-logo" style="color:#E17258;font-size:30px;" >BlendStation</strong>
                    </div>  

                </div>
                <div class="col-md item">
                    <div class="footer-item">
                        <nav class="footer-nav">
                            <ul class="footer-mnu footer-mnu-line">
                                <li><a href="/#/" class="hover-link" data-title="主页"><span>主页</span></a></li>
                                <li><a href="/#/contact" class="hover-link" data-title="关于"><span>关于</span></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row justify-content-between items">
                <div class="col-md-auto col-12 item">
                    <nav class="footer-links">
                        <ul>
                            <li><a href="#!">服务条款</a></li>
                            <li><a href="#!">隐私策略</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="col-md-auto col-12 item">
                    <div class="copyright"><a href="https://beian.miit.gov.cn">© 2021 MeshTek. All rights reserved.皖ICP备2023014591号-1</a></div>
                </div>
            </div>
        </div>
    </div>
</footer><!-- End footer -->

</template>



<script>
export default {
  name: 'Footer'
}
</script>

