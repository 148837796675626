<template>

<section class="fw-row asset-bg ">
	<div class="container-fluid">
		<div class="row-fluid">
				<div class="spb_content_wrapper container"
					 style="padding-left:15%;padding-right:15%;">

					<!--<div class="one_third">
								<h3>电话</h3>
								<div class="sf-icon-box-content">
									<br />
                                                                        <p>19884152386</p>
                                                                        <br />
									Weekdays 9am to 5pm CET
									<p>Join the live chat by using the chat bubble in the lower right corner of your page
										<br />
                                                                        </p>
								</div>
					</div>-->
					<div class="one_third">
								<br><br><br><br>
								<h5>邮件地址</h5>
								<div class="sf-icon-box-content">
									<br />
                                                                        <p>meshtek@qq.com</p>
                                                                        <br />
									目前blendstation要求您的部署环境只需要最基本联网条件即可，对于因为公司安全策略要求不能联网的单位需要联系我们授权，关于授权费用以及软件定制等更多问题，请发邮件咨询我们 <a href="mailto:meshtek@qq.com">meshtek@qq.com</a>
									我们会尽快联系您
									<br />
								</div>
					</div>
					<div class="one_third last">
								<br><br><br><br><br><br><br>
								<h5>技术支持群</h5>
								<div class="sf-icon-box-content">
									<br />
                                                                        <img decoding="async" src="@/assets/img/contact/qq_cluster.png" width= 320 height= 320>
									<p>
										<a
href="http://wpa.qq.com/msgrd?v=3&uin=502506320&site=qq&menu=yes">QQ联系我们</a> 
									</p>
								</div>
					</div>
					<div class="clearboth"></div>

				</div>
		</div>
	</div>
</section>

</template>


<script>
export default {
  name: 'AboutUs'
}
</script>



