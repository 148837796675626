<template>
<div id="industry" class="wp-block-group is-style-box mt-5 p-5 container has-background is-layout-flow wp-block-group-is-layout-flow" style="background-color:#f2f7ff;text-align:center;">
<p style="text-align:center;color:#0066ff;font-size:50px;">支持多种操作系统</p>



<!--<p class="has-medium-font-size">BlendStation支持多种现代主流硬件平台，包括Nvidia，AMD，Intel等.</p>-->



<div class="wp-block-columns are-vertically-aligned-center mb-0 mt-5 text-center is-layout-flex wp-container-core-columns-is-layout-3 wp-block-columns-is-layout-flex">


<img src="@/assets/img/os/window.png" width="100">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
<img src="@/assets/img/os/linux.png" height="100">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
<img src="@/assets/img/os/ubuntu.png" width="100">
<!--
<img src="@/assets/img/os/ubuntu.png" width="100">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
<img src="@/assets/img/os/deepin.png" width="100">
-->
</div>
</div>
</template>
