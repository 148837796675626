<template>
                <p><strong>运行监控</strong></p>
                <p>当我们配置好了集群，以及添加了了blend渲染文件后，我们就可以进行正式渲染工作了了</p>
                <ol>
                    <li>首先，在BlendStation管理中心<em>blendstation-manager</em>进入作业管理界面，在你之前添加的作业中选择你想要渲染的作业点击绿色箭头按钮，就开始了你的集群渲染工作.</li>
                    <p style="font-size:12px">1-1 点击渲染</p>
                    <img decoding="async" src="@/assets/img/usage/render_job1.png">
                    <li>然后我们在BlendStaion管理中心<em>blendstation-manager</em>进入监控界面，可以查看到当前正在渲染的作业进度和各个机器的工作负载情况</li>
                    <p style="font-size:12px">2-1 开始渲染时</p> <img decoding="async" src="@/assets/img/usage/render_job2.png">
                    <p style="font-size:12px">2-2 渲染到中间时</p> <img decoding="async" src="@/assets/img/usage/render_job3.png">
                </ol>
</template>
