<template>
                <p><strong>前置条件</strong></p>
                <p>运行BlendStation的基本条件</p>
                <ol style="font-size:12px">
                    <li>一台或者多台用来工作的电脑,
                        也就是用来 运行 <strong>BlendStation 客户端软件</strong>. 你&rsquo;
                        无需单独安装 <a class="gdoc-markdown__link" href="https://www.blender.org/">Blender</a>.</li>
                    <li>一台用来运行控制中心软件的电脑,
                        <strong>BlendStaion 管理软件</strong>. 这个可以是上面电脑中的一台，或者是专门的一台电脑 .
                    </li>
                    <li>确保上面的机器在同一个局域网内，确保他们之间的网络能够相互联通.</li>
                </ol>
                <br>
                <p>需要进一步说明的是电脑配置需要满足以下3个条件</p>
                <ol style="font-size:12px;">
                     <li>CPU型号：X86类型</li>
                     <li>GPU型号：如果有用GPU的话，必须主要支持CUDA或者Optix的Nvdia显卡，支持HIP技术的AMD显卡以及支持oneAPI的英特尔显卡</li>
                     <li>操作系统类型：主要为Window平台和Linux，其中Linux平台为Ubuntu22以后版本</li>
                </ol>
</template>
