<template>
                <p><strong>如何低成本搭建本地渲染农场</strong></p>
                <ol>
                    <li>利用现有计算机:对于工作室用户和个人用户，可以购买各个电商平台的二手硬件搭建渲染集群，对于公司用户可以利用公司夜间计算机空闲进行渲染，充分发挥自由硬件</li>
                    <li>购买硬件选购技巧1，根据Blender官方的测试基准去选择性价比高的硬件,相关链接请点击 <a href="https://opendata.blender.org/benchmarks/query/?compute_type=CPU&group_by=device_name&blender_version=4.2.0  ">Blender CPU测试基准</a>, <a href="https://opendata.blender.org/benchmarks/query/?compute_type=OPTIX&compute_type=CUDA&compute_type=HIP&compute_type=METAL&compute_type=ONEAPI&group_by=device_name&blender_version=4.2.0">Blender GPU测试基准</a></li>
                    <li>购买硬件选购技巧2,尽量选购内存大的硬件，CPUGPU弱点也就是渲染时间长点，但是如果内存太小，可能作为Worker节点就没办法参加渲染分工</li>
                    <li>内存选购标准: 以自己团队日常工作中经常渲染的作业需要的内存作为选购参考，可以在这个参考上适当放大一些标准</li>
                    <li>对于内存依然不够的机器: 可以通过设置虚拟内存的方式在一定程度上解决，虚拟内存大约设置在真实物理内存的50%左右</li>
                </ol>
</template>
