<template>
                <p><strong>作业管理</strong></p>
                <p>当配置好集群了以后，我们就可以进行作业加了，如何进行作业管理，需要按照以下步骤:</p>
                <ol>
                    <li>作业管理，在BlendStation管理中心软件 <em>blendstation-manager</em>进入作业管理界面，选择添加作业，然后在路径中选中要渲染的blend工程文件，然后添加. </li>
                    <p style="font-size:12px"> 进入作业管理界面 </p>
                    <img decoding="async" src="@/assets/img/usage/add_job0.png">
                    2. 点击"添加作业"

                    <img decoding="async" src="@/assets/img/usage/add_job1.png">
                    3. 选择blend工程文件并打开后，可以在窗口看到该作业相关信息 
                    <img decoding="async" src="@/assets/img/usage/add_job2.png">
                    4. 确认无误后点击添加, 在作业管理界面就可以看到刚才添加的blend工程文件 
                    <img decoding="async" src="@/assets/img/usage/add_job3.png">
                </ol>
</template>
