<template>
                <p><strong>Windows平台安装</strong></p>
                <p>运行BlendStation的必要条件请参考<a href="/#/docs/condition">安装前置条件</a></p>
                <p>一般来说,
                    安装BlendStation只需要按照以下步骤:</p>
                <ol>
                    <li><a class="gdoc-markdown__link" href="https://www.meshtek.cn/#/download">下载 BlendStation</a>. 解压缩后，在bin目录下可以看到两个文件，一个叫BlendStation-manager，用来管理作业和集群的图形管理软件，另一个叫BlendStation-worker，主要完成具体渲染任务 </li>
                    <li>在每一台用来渲染的电脑上安装BlendStation客户端，也就是运行<code>blendstation-worker</code>. 可以在任意路径下运行，不必要求相同.</li>
                    <li>选择一台电脑作为管理整个集群的电脑上安装BlendStation管理中心,
                        在上面运行 <code>blendstation-manager</code>. </li><img decoding="async" src="@/assets/img/help/start.png">
                     <p style="color:blue">需要注意的是，Windows平台启动manager和worker的的时候操作系统会弹出防火墙设置，记得选择选择公用网络即可，否则Manger和Worker通信就会出问题 </p>
                </ol>
</template>
